<template>
  <div class="groupbuy-list">
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CoreGroupbuyingsListV2',
              }"
              >團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>團購列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
        <div class="col-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">團購列表</h4>
          <!-- use popover in desktop -->
          <!-- <BIconQuestionCircle
            class="d-none d-md-block"
            id="popover-tips"
          ></BIconQuestionCircle>
          <b-popover target="popover-tips" triggers="hover focus click">
            <div class="text-left">
              <ul>
                <li>準備中：開團設定完成，待團購開始日即生效</li>
                <li>開團中：於開團期限內進行中之團購</li>
                <li>待取貨：團購時間已截止，待顧客完成取貨</li>
                <li>
                  已完成：已完成出貨、顧客已取貨或訂單取消且退款完成之團購
                </li>
              </ul>
            </div>
          </b-popover> -->

          <!-- use modal in mobile -->
          <!-- <BIconQuestionCircle
            class="d-md-none"
            v-b-modal.modal-tips
          ></BIconQuestionCircle>
          <b-modal id="modal-tips" hide-header hide-footer>
            <div class="text-left">
              <ul>
                <li>準備中：開團設定完成，待團購開始日即生效</li>
                <li>開團中：於開團期限內進行中之團購</li>
                <li>待取貨：團購時間已截止，待顧客完成取貨</li>
                <li>
                  已完成：已完成出貨、顧客已取貨或訂單取消且退款完成之團購
                </li>
              </ul>
            </div>
          </b-modal> -->
        </div>
        <div class="col-6 d-flex justify-content-end">
          <!-- <b-button
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            href="https://store.poyabuy.com.tw/ "
            target="_blank"
            ><i class="fa fa-external-link" aria-hidden="true"></i>前往
            IMS</b-button
          > -->
          <b-button
            v-if="checkPermission([consts.CORE_GROUPBUYING_CREATE])"
            class="mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            @click="handleCreate"
            ><i class="fa fa-plus" aria-hidden="true"></i>我要開團</b-button
          >
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-12 col-xl-6">
          <div class="align-items-center mb-2 mb-xl-0">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="flex-shrink-0 ml-1 ml-xl-2 mb-0"
            >
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selectedTypes"
                :options="statusOptions"
                :aria-describedby="ariaDescribedby"
                @change="handleCheckedTypes()"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap mb-3 justify-content-end">
        <div class="row-searchbar d-flex mb-2 mb-md-0 mr-md-2">
          <b-form-select
            class="mr-2"
            style="width: 120px; height: 45px"
            v-model="keyword_type"
            :options="searchOptions"
          ></b-form-select>
          <div class="w-100">
            <b-form-input
              v-model="keyword"
              :placeholder="placeholderMap[keyword_type]"
              @keyup.enter="getGroupbuyList"
            ></b-form-input>
          </div>
        </div>

        <DateSearchPicker
          class="mr-md-2 mr-0"
          v-model="query"
          :disabledEndAtDates.sync="disabledEndAtDates"
        />

        <b-button @click="handleSearch"><i class="fa fa-search"></i></b-button>
        <b-button class="ml-2" variant="primary" @click="handleExport"
          >匯出</b-button
        >
      </div>

      <b-tabs v-model="currentTabIndex">
        <b-tab
          v-for="tab in tabs"
          :title="tab.title"
          :key="tab.status"
          @click="handleChangeTab"
        >
          <b-card-text>
            <div class="row">
              <div class="col-12">
                <b-table
                  striped
                  hover
                  responsive
                  :items="groupbuys"
                  :fields="fields"
                  :busy="isFetchGroupbuyList"
                >
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #cell(groupbuying_number)="data">
                    <router-link
                      :to="{
                        name: 'CoreGroupbuyingDetailV2',
                        params: {
                          groupbuyID: data.item.id,
                        },
                      }"
                    >
                      {{ data.item.title }}<br>
                      {{ data.item.groupbuying_number }}
                    </router-link>
                    <span class="ml-2 cursor-pointer" @click="showLiffLink(data.item)">
                      <i class="fa fa-link"></i>
                    </span>
                  </template>

                  <template #cell(title)="data">
                    <div>
                      {{ data.item.products[0] && data.item.products[0].title }}
                    </div>
                    <div class="mb-1">
                      {{
                        data.item.products[0] &&
                        data.item.products[0].sale_page_code
                      }}
                    </div>
                  </template>
                  <template #cell(picture)="data">
                    <b-img-lazy
                      v-if="
                        data.item.products[0] &&
                        data.item.products[0].image_list[0] &&
                        data.item.products[0].image_list[0].pic_url
                      "
                      class="thumb-image"
                      :src="
                        data.item.products[0] &&
                        data.item.products[0].image_list[0] &&
                        data.item.products[0].image_list[0].pic_url
                      "
                      alt="商品圖片"
                      width="50"
                      height="50"
                    />
                  </template>
                  <template #cell(branch_name)="data">
                    <div v-if="data.item.group">
                      {{ data.item.group.branch.name }}
                    </div>
                    <div class="mt-1" v-if="data.item.group">
                      {{ data.item.group.branch.branch_code }}
                    </div>
                  </template>

                  <template #cell(group)="data">
                    <div class="mb-1" v-if="data.item.group">
                      {{ data.item.group.name }}
                    </div>
                    <div v-if="data.item.group">
                      {{ data.item.group.group_number }}
                    </div>
                  </template>
                  <template #cell(date)="data">
                    <div class="mb-1">
                      {{
                        format(new Date(data.item.start_at), "yyyy-MM-dd HH:mm")
                      }}
                    </div>
                    <div>
                      {{
                        format(new Date(data.item.end_at), "yyyy-MM-dd HH:mm")
                      }}
                    </div>
                  </template>

                  <template #head(tracking_code)>
                    <div class="d-flex">
                      <span
                        class="d-flex cursor-pointer"
                        @click="handleSort('tracking_code')"
                      >
                        員編

                        <sort-caret
                          class="ml-2"
                          :status="
                            sortBy === 'tracking_code'
                              ? sortDesc
                                ? SORT_CARET_STATUS.DESC
                                : SORT_CARET_STATUS.ASC
                              : SORT_CARET_STATUS.INIT
                          "
                        ></sort-caret>
                      </span>
                    </div>
                  </template>
                  <template #cell(tracking_code)="data">
                    <div>
                      {{
                        data.item.main_id
                          ? "總公司開團"
                          : data.item.tracking_code
                      }}
                    </div>
                  </template>

                  <template #head(current_quantity)>
                    <div class="d-flex">
                      <span
                        class="d-flex cursor-pointer"
                        @click="handleSort('order_quantity')"
                      >
                        本團銷量

                        <sort-caret
                          class="ml-2"
                          :status="
                            sortBy === 'order_quantity'
                              ? sortDesc
                                ? SORT_CARET_STATUS.DESC
                                : SORT_CARET_STATUS.ASC
                              : SORT_CARET_STATUS.INIT
                          "
                        ></sort-caret>
                      </span>
                    </div>
                  </template>

                  <template #cell(current_quantity)="data">
                    {{ data.item.current_quantity || 0 }}
                  </template>

                  <template #cell(adjust_quantity_result)="data">
                    {{ data.item.current_quantity + data.item.adjust_quantity }}
                  </template>

                  <!-- <template #head(total_quantity)>
                    <div class="d-flex">
                      <span
                        class="d-flex cursor-pointer"
                        @click="handleSort('quantity')"
                      >
                        剩餘庫存

                        <sort-caret
                          class="mx-1"
                          :status="
                            sortBy === 'quantity'
                              ? sortDesc
                                ? SORT_CARET_STATUS.DESC
                                : SORT_CARET_STATUS.ASC
                              : SORT_CARET_STATUS.INIT
                          "
                        ></sort-caret>
                        /總數量
                      </span>
                    </div>
                  </template>

                  <template #cell(total_quantity)="data">
                    <div v-if="data.item.products[0]">
                      <span v-if="data.item.products[0].sku_list[0]">
                        {{ data.item.products[0].sku_list[0].quantity || 0 }}
                      </span>
                      /
                      <span v-if="data.item.products[0].sku_list[0]">
                        {{
                          (data.item.products[0].sku_list[0].quantity || 0) +
                          (data.item.products[0].sku_list[0].sale_quantity || 0)
                        }}
                      </span>
                    </div>
                  </template> -->

                  <template #head(date)>
                    <div class="d-flex">
                      <span
                        class="d-flex cursor-pointer"
                        @click="handleSort('start_at')"
                      >
                        開團

                        <sort-caret
                          class="mx-1"
                          :status="
                            sortBy === 'start_at'
                              ? sortDesc
                                ? SORT_CARET_STATUS.DESC
                                : SORT_CARET_STATUS.ASC
                              : SORT_CARET_STATUS.INIT
                          "
                        ></sort-caret>
                      </span>
                      /結團時間
                    </div>
                  </template>
                </b-table>
              </div>
            </div>

            <div
              class="
                d-flex
                justify-content-center
                align-items-center
                flex-column flex-md-row
              "
              style="margin-top: 80px"
            >
              <b-pagination
                class="separated mb-2 mb-md-0"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                aria-controls="groupbuyList"
                @change="handleChangePage"
              ></b-pagination>
              <div class="ml-2">
                每頁顯示數量<b-form-select
                  v-model="perPage"
                  :options="perPageOptions"
                  style="width: 70px"
                  class="ml-2"
                  @change="handleChangePage"
                ></b-form-select>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal id="liff-link-modal" footer-class="d-flex justify-content-center" hide-header>
      <div>{{ selectedGroupbuying.title }}</div>
      <b-textarea v-model="liffLink" class="mt-2" @click="selectLiffLink"></b-textarea>
      <template #modal-footer>
        <b-button size="lg" variant="danger" @click="$bvModal.hide('liff-link-modal')">關閉</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script lang="js">
import { format, set } from "date-fns";
import { BTable } from "bootstrap-vue";
import { mapState } from "vuex";
import groupbuyApi from "@/apis/core-groupbuying";
import SortCaret, { SORT_CARET_STATUS } from "@/components/SortCaret";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker";
import store from "@/store";
import checkPermission from "@/mixins/checkPermission";

export default {
  mixins: [checkPermission],
  components: {
    BTable,
    SortCaret,
    DateSearchPicker,
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    disabledEndAtDates() {
      return { to: new Date(this.query.start_at) };
    },
    currentTabStatus() {
      return this.tabs[this.currentTabIndex].status;
    },
    tabs() {
      let temp = [];

      if (this.checkPermission([this.consts.CORE_GROUPBUYING_LIST_DRAFT])) {
        temp.push({
          id: "draft",
          title: "草稿",
          status: 8,
        });
      }

      if (this.checkPermission([this.consts.CORE_GROUPBUYING_LIST_WAITING])) {
        temp.push({
          id: "waiting",
          title: "準備中",
          status: 0,
        });
      }

      if (this.checkPermission([this.consts.CORE_GROUPBUYING_LIST_GOING])) {
        temp.push({
          id: "going",
          title: "開團中",
          status: 1,
        });
      }

      if (this.checkPermission([this.consts.CORE_GROUPBUYING_LIST_CONFIRMED])) {
        temp.push({
          id: "confirmed",
          title: "已結單",
          status: 2,
        });
      }

      if (this.checkPermission([this.consts.CORE_GROUPBUYING_LIST_FINISHED])) {
        temp.push({
          id: "finished",
          title: "待取貨",
          status: 3,
        });
      }

      if (this.checkPermission([this.consts.CORE_GROUPBUYING_LIST_PENDING])) {
        temp.push({
          id: "pending",
          title: "暫停收單",
          status: 4,
        });
      }

      if (this.checkPermission([this.consts.CORE_GROUPBUYING_LIST_CANCELED])) {
        temp.push({
          id: "cancelled",
          title: "未成團",
          status: 5,
        });
      }

      if (this.checkPermission([this.consts.CORE_GROUPBUYING_LIST_ARCHIVED])) {
        temp.push({
          id: "archived",
          title: "已完成",
          status: 6,
        });
      }

      if (this.checkPermission([this.consts.CORE_GROUPBUYING_LIST_DELETED])) {
        temp.push({
          id: "deleted",
          title: "已刪除",
          status: 9,
        });
      }

      return temp;
    }
  },
  data() {
    return {
      SORT_CARET_STATUS,
      isFetchGroupbuyList: false,
      keyword_type: "product", // product or group
      keyword: "",
      searchOptions: [
        {
          text: "商品",
          value: "product",
        },
        // {
        //   text: "群組",
        //   value: "group",
        // },
        // {
        //   text: "員編",
        //   value: "tracking_code",
        // },
      ],
      placeholderMap: {
        product: "搜尋商品名稱/編號",
        group: "搜尋群組名稱",
        tracking_code: "搜尋員編",
      },
      groupbuys: [],
      breadcrumb: [
        {
          text: "團購管理",
          href: "#",
        },
        {
          text: "團購列表",
          href: "#",
          active: true,
        },
      ],
      sortBy: "start_at",
      sortDesc: false,
      fields: [
        {
          key: "order",
          label: "順序",
          thClass: "text-center",
        },
        {
          key: "groupbuying_number",
          label: "團購編號",
          thClass: "text-center",
        },
        {
          key: "title",
          label: "商品名稱/編號",
          thClass: "text-center",
        },
        {
          key: "picture",
          label: "商品圖片",
          thClass: "text-center",
        },
        // {
        //   key: "products[0].computed_unit_price",
        //   label: "單價",
        //   thClass: "text-center",
        // },
        // {
        //   key: "branch_name",
        //   label: "開團分店/編號",
        //   thClass: "text-center",
        // },
        // {
        //   key: "group",
        //   label: "群組名稱/編號",
        //   thClass: "text-center",
        // },
        // {
        //   key: "tracking_code",
        //   label: "員編",
        //   thClass: "text-center",
        // },
        {
          key: "current_quantity",
          label: "本團銷量",
          class: "text-center",
        },
        {
          key: "adjust_quantity",
          label: "調整數量",
          class: "text-center",
        },
        {
          key: "adjust_quantity_result",
          label: "前台銷量",
          class: "text-center",
        },
        // {
        //   key: "total_quantity",
        //   label: "剩餘庫存/總數量",
        //   class: "text-center",
        // },
        {
          key: "date",
          label: "開團/截團時間",
          class: "text-center",
        },
      ],
      perPageOptions: [10, 50, 100],
      currentTabIndex: 0,
      perPage: 10,
      currentPage: 1,
      total: 0,
      query: {
        start_at: "",
        end_at: "",
      },
      showGroupbuyListType: false,
      statusOptions: [],
      selectedTypes: [1, 2, 3],
      selectedGroupbuying: {},
      liffLink: '',
    };
  },

  created() {
    const { keyword_type, keyword, tab } = this.$route.query || {};
    this.currentTabIndex = this.tabs.findIndex((t) => t.id === tab);
    this.currentTabIndex = this.currentTabIndex === -1 ? 0 : this.currentTabIndex;
    if (keyword || keyword_type) {
      this.keyword = keyword;
      this.keyword_type = keyword_type;
    }
    this.getGroupbuyList();
  },

  methods: {
    format,
    handleSearch() {
      this.currentPage = 1;
      this.getGroupbuyList();
    },

    async getGroupbuyList() {
      const { keyword } = this.$route.query || {};
      if (this.keyword && !keyword) {
        this.$router.push({
          query: {
            keyword_type: this.keyword_type,
            keyword: this.keyword,
          },
        });
      }
      try {
        this.isFetchGroupbuyList = true;
        let params = {
          status: this.currentTabStatus,
          keyword_type: this.keyword_type,
          keyword: this.keyword ? this.keyword : "",
          page: this.currentPage,
          per_page: this.perPage,
          sort_by: this.sortBy,
          ...(this.sortDesc ? { desc: "" } : { asc: "" }),
          ...(this.query.start_at
            ? {
                start_at: format(
                  set(new Date(this.query.start_at), {
                    hours: "00",
                    minutes: "00",
                    seconds: "00",
                  }),
                  "yyyy-MM-dd HH:mm:ss"
                ),
              }
            : {}),
          ...(this.query.end_at
            ? {
                end_at: format(
                  set(new Date(this.query.end_at), {
                    hours: "23",
                    minutes: "59",
                    seconds: "59",
                  }),
                  "yyyy-MM-dd HH:mm:ss"
                ),
              }
            : {}),
        };

        if (this.selectedTypes.length !== 0 && this.showGroupbuyListType) {
          params.types = this.selectedTypes;
        }

        const { data } = await groupbuyApi.getGroupbuyList(params);
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.groupbuys = data.data;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetchGroupbuyList = false;
    },

    async handleExport() {
      let url =
        `${process.env.VUE_APP_API_BASE_URL}/admin/organizations/${this.organization.id}` +
        `/general-groupbuyings/action/export?status=${this.currentTabStatus}&sort_by=${this.sortBy}&token=${store.state.auth.token}`;

      if (this.sortDesc) {
        url += "&desc=";
      } else {
        url += "&asc=";
      }

      if (this.query.start_at) {
        url += `&start_at=${format(
          set(new Date(this.query.start_at), {
            hours: "00",
            minutes: "00",
            seconds: "00",
          }),
          "yyyy-MM-dd HH:mm:ss"
        )}`;
      }

      if (this.query.end_at) {
        url += `&end_at=${format(
          set(new Date(this.query.end_at), {
            hours: "23",
            minutes: "59",
            seconds: "59",
          }),
          "yyyy-MM-dd HH:mm:ss"
        )}`;
      }

      if (this.keyword_type) {
        url += `&keyword_type=${this.keyword_type}&keyword=${this.keyword}`;
      }
      console.log("url", url);
      window.open(url);
    },

    handleChangeTab() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.getGroupbuyList();
        this.$router.push({ query: { tab: this.tabs[this.currentTabIndex].id } });
      });
    },

    handleChangePage() {
      this.$nextTick(() => {
        this.getGroupbuyList();
      });
    },

    handleCreate() {
      this.$router.push({ name: "CoreGroupbuyingsCreateV2" });
    },

    showLiffLink(groupbuying) {
      this.selectedGroupbuying = groupbuying
      this.liffLink = `https://liff.line.me/${this.organization.liff_id}/core-group-buying/${groupbuying.id}`
      this.$bvModal.show('liff-link-modal')
    },
    selectLiffLink(event) {
      event.target.select()
    }
  }
}
</script>
